.container {
  margin: 12px 0 26px;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;

  .paragraph {
    margin: 0 20px;

    h2 {
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .joinUs {
      color: #000;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 30px;
    }

      .yourData {
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

    .privacy {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #000;
      height: 35px;
      width: 236px;
      background: transparent;
      cursor: pointer;
      text-decoration: none;

      color: #000;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
    }
  }

  .blackSection {
    margin: 18px 0 30px;
    background: #000;
    padding: 24px 30px 32px 26px;
    color: white;

    .doings {
      margin: 21px 0 0 16px;
      display: grid;
      grid-template-columns: 136px 50%;
      gap: 14px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        padding-left: 9px;
        border-left: 1px solid white;
      }
    }

    .yourVoiceTitle {
      color: #C3FF62;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    h1 {
      padding-left: 16px;
      color: #C3FF62;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .learnMore {
    margin: 35px 20px 106px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.containerNotConnected {
  margin: 12px 0 220px;
}

.modal {
  width: 100%;
  
  .text {
    display: flex;
    justify-content: center;
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }

  .signInButton {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 1;

    .button {
      flex: 1;
      width: 100%;
      margin: 25px 0 12px;
      background-color: #c3ff62;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #000;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
