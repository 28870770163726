.container {
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  height: calc(100% - 74px);
  z-index: 50;
  max-width: 425px;

  .content {
    height: 100%;
    width: 100%;
    bottom: 0;
    z-index: 40;
    display: flex;
    flex: 1;

    .closeIcon {
      position: absolute;
      top: 26px;
      right: 10px;
      z-index: 45;
    }

    .children {
      width: 100%;
      background: #fff;
      overflow: auto;
      border: 2px solid #000;
      border-bottom: none;
    }
    
    .childrenPadding {
      padding: 30px 15px 15px;

      @media (min-width: 350px) {
        padding: 30px 35px 30px 30px;
      }
    }

    .leftMargin {
      border-right: 8px solid #000;
      border-top: 8px solid transparent;
      width: 0;
    }
  }
}
